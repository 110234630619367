@import '../../assets/scss/app-variables';

.auth-container {
  display: flex;
  height: calc(100% - 38px);

  .slideBodyAuth {
    display: flex;
    align-items: center;
    flex-direction: column;

    .content {
      .authForm {
        input {
          margin-bottom: $space-between-x2;
        }
      }
  
      .authError {
        color: $error-color;
        margin-bottom: $space-between;
      }
  
      .mailSuccess {
        color: $secondary-blue;
        margin-bottom: $space-between;
      }
  
      .link {
        margin: 0 0 $space-between-x2;
        text-align: left;
      }
  
      .disclaimerContainer {
        color: $secondary-blue;
        display: flex;
        justify-content: center;
        margin-bottom: $space-between-x2;
      
        .disclaimer {
          background-color: #F1F0F7;
          border-radius: 8px;
          display: flex;
          align-items: center;
          padding: 1rem;
          max-width: 19.5rem;
        
          .balanceSheet {
            margin-right: 1rem;
          }
        }
      }
  
      .separator {
        display: flex;
        align-items: center;
        text-align: center;
        color: $primary-blue;
        font-size: 0.938rem; // 15px
        font-weight: 600;
        margin: $space-between 0;
      
        &::before,
        &::after {
          content: '';
          flex: 1;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        
        &:not(:empty)::before {
          margin-right: .8em;
        }
        
        &:not(:empty)::after {
          margin-left: .8em;
        }
      }
      
      .socialButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
      
        .googleBtn {
          border: 2px outset #000 !important;
          border-radius: 5px !important;
          box-shadow: none !important;
          margin-bottom: 10px;
          min-width: 228px;
          padding: 0.66rem 1.5rem !important;
          
          &:focus {
            outline: none;
          }
        
          div {
            display: flex;
            margin-right: 5px !important;
            padding: 0 !important;
          }
          
          span {
            color: black;
            padding: 0 !important;
          }
        }
        
        .apple-auth-btn {
          border-color: #000;
          border-radius: 5px;
          font-weight: 500;
          min-width: 228px;
          padding: 0 1rem;
        }
      }
    }
  }

  .manPcContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    width: 50%;
    
    img {
      width: 576px;
      height: 678px;
    }
  }
}

/* Modal */
.smallModalContainer {
  padding: 2rem;

  .buttonsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: $space-between-x2;

    .noBtn {
      margin: $space-between 0 0;
    }
  }
}

@include screen('large') {
  .auth-container {
    justify-content: space-between;

    .slideBodyAuth {
      width: calc(50% - 20px);

      .content {
        width: 365px;

        h2,
        .link,
        .disclaimerContainer {
          margin-bottom: $space-between-x3;
        }
      }
    }
  }
}
  
@include screen('small') {
  .auth-container {
    height: calc(100% - 25px);
    flex-direction: column;

    .slideBodyAuth {
      height: 100%;

      .socialButtons {
        margin-bottom: $space-between;
      }
    }

    .manPcContainer {
      display: none;
    }
  }

  .smallModalContainer {
    height: 30vh;
    overflow-y: auto;
    padding: 2rem 2rem 3rem;
  }
}