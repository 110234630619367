@import '../../assets/scss/app-variables';

.slideBodyMember {
  display: flex;
  flex-direction: column;
  height: calc(100% - 38px);

  .title-button {
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: $space-between;
    }

    .btnContainer button:first-child {
      margin-right: $space-between;
    }
  }

  .memberContainer {
    display: flex;
    justify-content: center;
    margin-top: auto;

    img {
      width: 242px;
      height: 264px;
    }
  }
}

@include screen('large') {
  .slideBodyMember {
    .title-button {
      margin-top: 122px;

      h2 {
        align-self: center;
      }

      .btnContainer {
        margin-top: $space-between-x2;
      }
    }
  }
}

@include screen('small') {
  .slideBodyMember {
    height: calc(100% - 25px);

    .memberContainer img {
      width: 180px;
      height: 241px;
    }
  }
}