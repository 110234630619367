@import '../../../assets/scss/app-variables';

.smallModal-container {
  display: table;
  height: 100%;
  position: fixed;
  left: 0;
  transform: translateY(110%);
  top: 0;
  width: 100%;
  z-index: 1500;
  
  .smallModal {
    background-color: white;
    border-radius: 22px;
    box-shadow: 0px -6px 20px 0px $black-20;
    width: 100vw;
    max-width: 40rem;
    max-height: 40rem;
    margin: 0 auto;
  }
}
  
@keyframes smallModalUnfoldIn {
  0% {
    transform: translateY(110%);
  }
  100% {
    transform: translateY(25%);
  }
}
  
@keyframes smallModalUnfoldOut {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(110%);
  }
}
  
@keyframes smallModalUnfoldInSmall {
  0% {
    transform: translateY(110%);
  }
  100% {
    transform: translateY(60%);
  }
}
  
@keyframes smallModalUnfoldOutSmall {
  0% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(110%);
  }
}

@include screen('large') {
  .smallModal-container {
    &.smallModalOpen {
      transform: translateY(110%);
      animation: smallModalUnfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    
    &.smallModalClosed {
      transform: translateY(25%);
      animation: smallModalUnfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
  }
}
  
@include screen('small') {
  .smallModal-container {
    &.smallModalOpen {
      transform: translateY(110%);
      animation: smallModalUnfoldInSmall 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    
    &.smallModalClosed {
      transform: translateY(60%);
      animation: smallModalUnfoldOutSmall 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
  }
}
  