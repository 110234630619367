@import '../../assets/scss/app-variables';

.slideBodyNoProblem {
  display: flex;
  flex-direction: column;
  height: calc(100% - 13px);

  h2 {
    margin-bottom: $space-between;
  }

  .checkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $space-between-x2 0;

    img {
      width: 379px;
      height: 488px;
    }
  }
}

@include screen('large') {
  .slideBodyNoProblem {
    align-items: center;
    justify-content: center;
  }
}

@include screen('small') {
  .slideBodyNoProblem {
    height: 100%;

    .checkContainer {
      margin: $space-between 0;

      img {
        width: 193px;
        height: 257px;
      }
    }
  }
}