@import '../../assets/scss/app-variables';

// select
.slideBodyOptions {
  display: flex;
  flex-direction: column;
  height: calc(100% - 38px);

  .option {
    background-color: $shadow;
    border: 1px solid $shadow;
    border-radius: 8px;
    color: $primary-blue;
    cursor: pointer;
    padding: $space-between 0;
    text-align: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  
    &.activeOption {
      background-color: $secondary-blue;
      border-color: $secondary-blue;
      color: $white;
    }
  }
}

// comment
.comment-container {
  display: flex;
  height: calc(100% - 38px);

  .slideBodyComment {
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: $space-between;
    }

    p span {
      color: $secondary-blue;
    }

    .disclaimerContainer {
      color: $secondary-blue;
      display: flex;
      justify-content: center;
      margin: $space-between-x2 0;
    
      .disclaimer {
        background-color: #F1F0F7;
        border-radius: 8px;
        display: flex;

        img {
          // align-self: flex-end;
          width: 344px;
          height: 114px;
        }

        p {
          margin: 16px 16px 0;
          text-align: center;
        }
      }
    }

    .commentSmallTextArea {
      margin-bottom: $space-between-x2;
    }
  }

  .commentTextArea {
    width: 50%;
    max-width: 50%;
    min-width: 50%;
    margin: $space-between-x2 0;
    height: calc(100% - 85px) !important;
  }
}

@include screen('large') {
  // select
  .slideBodyOptions {
    align-items: center;
    margin-top: $space-between-x3;

    h2 {
      align-self: center;
      margin-bottom: $space-between-x3;
    }

    .option {
      width: 610px;
    }
  }

  // comment
  .comment-container {
    justify-content: space-between;

    .slideBodyComment {
      margin-top: $space-between-x3;
      width: 344px;

      .disclaimerContainer {
        .disclaimer {
          flex-direction: column;

          img {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }

      .commentSmallTextArea {
        display: none;
      }
    }
  }
}

@include screen('small') {
  // select
  .slideBodyOptions {
    height: calc(100% - 25px);
  }

  // comment
  .comment-container {
    flex-direction: column;
    height: calc(100% - 25px);

    .slideBodyComment {
      .disclaimerContainer {
        margin: $space-between-x2 0 $space-between;

        .disclaimer {
          img {
            align-self: flex-end;
            width: 87px;
            height: 54px;
          }

          p {
            margin: 16px 16px 16px 0;
            text-align: right;
          }
        }
      }
    }

    .commentTextArea {
      display: none;
    }
  }
}

