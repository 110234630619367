@import '../../../assets/scss/app-variables';

.improvements-container {
  display: flex;
  height: calc(100% - 38px);

  .slideBodyImprovements {
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: $space-between;
    }

    p {
      span {
        color: $secondary-blue;
      }

      &.mb {
        margin-bottom: $space-between;
      }
      
      // &.noImprovement {
      //   color: $black-50;
      //   padding: $space-between 0 0;
      //   text-align: center;
      // }
    }

    .improvementsSmallContainer {
      margin: $space-between-x2 0;
    }

    .button-noImprovement-container {
      display: flex;
      flex-direction: column;
    }
  }

  .improvementsContainer,
  .improvementsSmallContainer {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .improvement {
      background-color: $shadow;
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin: 4px;
      min-width: 3%;
      padding: 6px 12px;
      pointer-events: none;
    
      &.activeQ {
        background-color: $secondary-blue;
        box-shadow: 0px 4px 13px $secondary-blue-40;
        cursor: pointer;
        pointer-events: auto;
      
        p {
          color: $white;
        }
      }
    
      &.lowScore {
        background-color: $accent-blue;
        cursor: pointer;
        pointer-events: auto;
      }
    
      p {
        color: $primary-blue;
      }
    }
  }
}

@include screen('large') {
  .improvements-container {
    justify-content: space-between;

    .slideBodyImprovements {
      margin-top: $space-between-x3;
      width: 360px;

      h2 {
        margin-bottom: $space-between-x3;
      }

      .improvementsSmallContainer {
        display: none;
      }

      .button-noImprovement-container {
        margin-top: $space-between-x2;
      }
    }

    .improvementsContainer {
      margin-top: ($space-between-x3 * 2);
      height: calc(100% - 60%);
      width: 50%;
    }
  }
}

@include screen('small') {
  .improvements-container {
    flex-direction: column;
    height: calc(100% - 25px);

    .slideBodyImprovements {
      p {
        font-size: 0.75rem; // 12px
        line-height: 1rem; // 16px   
      }
      
      .button-noImprovement-container {
        margin-bottom: $space-between-x2;
      }
    }

    .improvementsContainer {
      display: none;
    }
  }
}