@import '../../assets/scss/app-variables';

.half-bg {
  background: linear-gradient(290.33deg, #FEDEDD 6.97%, #FFC8C8 56.56%, #FFB29A 96.71%);
  position: fixed;
  right: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  z-index: 1;
}

.slideContainer {
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;

    img {
      width: 65px;
      height: 13px;
    }
  }

  .arrow-progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    margin-bottom: 5px;

    .crossContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 1rem;
  
      .closeCross {
        cursor: pointer;
        max-width: 1rem;
      }
    }
      
    .arrowContainer {
      display: flex;
      align-items: center;
  
      .backArrow {
        cursor: pointer;
        max-width: 1.1rem;
      }
    }

    .progressBar {
      display: flex;
      align-items: center;
      padding-left: 5%;
      height: 8px;
      width: 100%;
  
      .progressValueOne {
        background-color: $primary-blue;
        border-radius: 8px;
        height: 100%;
      }
  
      span {
        color: $primary-blue;
        font-size: 0.75rem;
        font-weight: 600;
        margin: 0 10px;
      }
  
      .progressValueTwo {
        background-color: $secondary-blue;
        border-radius: 8px;
        height: 100%;
      }
    }
  }

  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include screen('small') {
  .slideContainer {
    .logoContainer {
      display: none;
    }
  }
}
