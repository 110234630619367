@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;900&display=swap');

/* typography */
$main-font-family: 'Poppins', sans-serif;

/* color */
$primary-blue: #063e75;
$primary-blue-50: rgba($primary-blue, 0.5);
$secondary-blue: #2b88f1;
$secondary-blue-20: rgba($secondary-blue, 0.2);
$secondary-blue-40: rgba($secondary-blue, 0.4);
$accent-blue: #bedfff;
$error-color: #ff0000;
$white: #fff;
$black: #000;
$black-50: rgba($black, 0.5);
$black-20: rgba($black, 0.2);
$shadow: #e2e9ef;
$intro-background-color: #8ec7ff;

/* Space between */
$space-between: 20px;
$space-between-x2: 40px;
$space-between-x3: 60px;

/* responsive */
$breakpoints: (
  'small': ('min': 0rem, 'max': 25.938rem), // 0 - 415px
  'large': ('min': 26rem, 'max': 160rem) // 416px - 2560px
);

/* @mixin managing breakpoint medias */
@mixin screen($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @each $key, $props in $breakpoints {
      @if $key == $breakpoint {
        @media (min-width: map-get($props, 'min')) and (max-width: map-get($props, 'max')) {
          @content;
        }
      }
    }
  } @else {
      @warn "Unfortunately, `#{$breakpoint}` can not be found. "
        + "Available breakpoints are : #{map-keys($breakpoints)}";
  }
}