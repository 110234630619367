@import '../../assets/scss/app-variables';

.enrollment-container {
  display: flex;
  height: calc(100% - 13px);

  .slideBodyEnrollment {
    display: flex;
    flex-direction: column;

    .content {
      h2 {
        margin-bottom: $space-between;
      }

      p.description {
        color: $secondary-blue;
        margin-bottom: $space-between-x2;
      }

      .enrollmentForm {
        input {
          margin-bottom: $space-between-x2;
        }
      }
  
      .authError {
        color: $error-color;
        margin-bottom: $space-between;
      }

      .checkboxContainer {
        display: flex;
        align-items: center;
        align-self: center;
        max-width: 23rem;
        margin-bottom: $space-between-x2;
    
        p {
          color: $secondary-blue;
          margin: 0 0 0 5px;
          text-align: left;
        }
      }

      .separator {
        display: flex;
        align-items: center;
        text-align: center;
        color: $primary-blue;
        font-size: 0.938rem; // 15px
        font-weight: 600;
        margin: $space-between 0;
      
        &::before,
        &::after {
          content: '';
          flex: 1;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        
        &:not(:empty)::before {
          margin-right: .8em;
        }
        
        &:not(:empty)::after {
          margin-left: .8em;
        }
      }
      
      .socialButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
      
        .googleBtn {
          border: 2px outset #000 !important;
          border-radius: 5px !important;
          box-shadow: none !important;
          margin-bottom: 10px;
          min-width: 228px;
          padding: 0.66rem 1.5rem !important;
          
          &:focus {
            outline: none;
          }
        
          div {
            display: flex;
            margin-right: 5px !important;
            padding: 0 !important;
          }
          
          span {
            color: black;
            padding: 0 !important;
          }
        }
        
        .apple-auth-btn {
          border-color: #000;
          border-radius: 5px;
          font-weight: 500;
          min-width: 228px;
          padding: 0 1rem;
        }
      }
    }
  }

  .floatingContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    width: 50%;
    
    img {
      width: 507px;
      height: 663px;
    }
  }
}

@include screen('large') {
  .enrollment-container {
    justify-content: space-between;

    .slideBodyEnrollment {
      width: calc(50% - 20px);

      .content {
        width: 550px;
      }
    }
  }
}
  
@include screen('small') {
  .enrollment-container {
    height: calc(100% - 25px);
    flex-direction: column;

    .slideBodyEnrollment {
      height: 100%;

      .socialButtons {
        margin-bottom: $space-between;
      }
    }

    .floatingContainer {
      display: none;
    }
  }
}