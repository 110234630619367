@import '../../assets/scss/app-variables';

.feedbackModalContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  position: relative;

  .closeCross {
    cursor: pointer;
    max-width: 1rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
  
  p span {
    color: $secondary-blue;
  }
  
  .buttonsContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: $space-between-x2;

    .laterBtn {
      margin: $space-between 0 0;
    }
  }
}

@include screen('small') {
  .feedbackModalContainer {
    height: 60vh;
    overflow-y: auto;

    .buttonsContainer {
      .btn:not(.flat) {
        padding: 12px 30px;
      }
    }
  }
}