@import '../../../assets/scss/app-variables';

.scale-container {
  display: flex;
  height: calc(100% - 38px);

  .slideBodyScale {
    display: flex;
    flex-direction: column;
  }

  .scaleInputQuestionContainer {
    display: flex;
    justify-content: center;

    .scaleInputContainer {
      width: 100%;

      .scaleInput {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: $space-between auto;
        padding: 0 ($space-between + 10px);

        .scaleInputBtn {
          cursor: pointer;
          text-align: center;

          .outerInput {
            border: 2px solid $primary-blue;
            border-radius: 50%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;

            .innerInput {
              background: $secondary-blue;
              border-radius: 50%;
              width: 16px;
              height: 16px;
            }
          }
        }

        .dividerLine {
          border-bottom: 4px dotted $primary-blue;
          margin: 0 5px;
          height: 1px;
          width: 100%;
        }
      }

      .radioLegend {
        display: flex;
        align-items: center;
        justify-content: space-between;
      
        p {
          color: $secondary-blue;
          max-width: 80px;
          text-align: center;
        }
      }
    }
  }
}

@include screen('large') {
  .scale-container {
    justify-content: space-between;

    .slideBodyScale {
      margin-top: $space-between-x3;
      width: 346px;

      h2 {
        margin-bottom: $space-between-x3;
      }
    }

    .scaleInputQuestionContainer {
      margin-top: ($space-between-x3 * 2);
      width: 50%;
    }
  }
}

@include screen('small') {
  .scale-container {
    flex-direction: column;
    height: calc(100% - 25px);

    .slideBodyScale h2 {
      margin-bottom: $space-between;
    }

    .scaleInputQuestionContainer {
      margin-top: $space-between-x2;
    }
  }
}
