@import '../../assets/scss/app-variables';

.alreadySendContainer {
  background-color: #ffecec;
  display: flex;
  justify-content: center;
  height: calc(100% - 20px);
  padding: 20px 20px 0;

  .slideBodyAlreadySend {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      h4 {
        margin-bottom: $space-between;
      }

      p {
        margin-bottom: $space-between-x2;
        text-align: center;
      }

      img {
        width: 86px;
        height: 84px;
      }
    }

    .motorbikeContainer {
      img {
        width: 518px;
        height: 518px;
      }
    }
  }
}

@include screen('small') {
  .alreadySendContainer {
    .slideBodyAlreadySend {
      .motorbikeContainer img {
        width: 279px;
        height: 290px;
      }
    }
  }
}
