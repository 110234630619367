@import '../../assets/scss/app-variables';

.slideBodyBravo {
  display: flex;
  flex-direction: column;
  height: calc(100% - 13px);

  h2 {
    margin-bottom: $space-between;
  }

  p {
    color: $secondary-blue;
  }

  .bravoListContainer {
    display: flex;
    flex-direction: column;
    margin: $space-between-x2 0;

    .listContent {
      display: flex;
      align-items: center;

      &:first-child {
        margin-bottom: $space-between;
      }

      .bulletList {
        background-color: $secondary-blue;
        border-radius: 50%;
        box-shadow: 0px 4px 13px $secondary-blue;
        height: 10px;
        min-width: 10px;
        max-width: 10px;
        margin-right: $space-between;
      }

      p {
        color: $primary-blue;
      }
    }
  }

  .imageContainer {
    background-color: #F1F0F7;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: $space-between-x2;

    .contentContainer {
      display: flex;
      align-items: center;
      padding: 0 16px;

      img {
        width: 92px;
        height: 76px;
      }

      p {
        color: $primary-blue;
        text-align: left;
        
        span {
          color: $secondary-blue;
        }
      }
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .feedbackBtn button a {
      color: $white;
      font-size: 1rem; // 16px
      font-weight: 600;
      line-height: 1.5rem; // 24px
      text-decoration: none;
    }
  }
}

@include screen('large') {
  .slideBodyBravo {
    align-items: center;
    justify-content: center;
  }
}

@include screen('small') {
  .slideBodyBravo {
    height: 100%;
    
    .buttonsContainer {
      .feedbackBtn button {
        padding: 12px 46px;
      }
    }
  }
}