@import '../../../assets/scss/app-variables';

.transition-container {
  display: flex;
  height: calc(100% - 38px);

  .slideBodyTransition {
    display: flex;
    flex-direction: column;

    h2 {
      margin-bottom: $space-between;
    }

    p span {
      color: $secondary-blue;
    }

    .advicesContainer {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: $space-between 0;
      padding: $space-between 0;

      p {
        text-align: center;

        &:not(:last-child) {
          margin-bottom: $space-between;
        }
      }
    }

    .smallOkContainer {
      margin: 0 0 $space-between (-$space-between);

      img {
        width: 170px;
        height: 74px;
      }
    }
  }

  .okContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50%;

    img {
      width: 386px;
      height: 597px;
    }
  }
}

@include screen('large') {
  .transition-container {
    justify-content: space-between;

    .slideBodyTransition {
      margin-top: $space-between-x3;
      width: 335px;

      h2 {
        margin-bottom: $space-between-x3;
      }
      
      .advicesContainer {
        border: 1px solid $shadow;
        border-radius: 8px;
        margin: $space-between-x2 0;
      }
  
      .smallOkContainer {
        display: none;
      }
    }
  }
}

@include screen('small') {
  .transition-container {
    flex-direction: column;
    height: calc(100% - 25px);

    .okContainer {
      display: none;
    }
  }
}