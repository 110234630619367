@import '../../assets/scss/app-variables';

.invalidContainer {
  background-color: #082c54;
  display: flex;
  justify-content: center;
  height: calc(100% - 20px);
  padding: 20px 20px 0;

  .slideBodyInvalid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        color: $white;
        margin-bottom: $space-between;
      }

      p {
        color: $white;
        text-align: center;
      }
    }

    .personContainer {
      img {
        margin-left: -20px;
        width: calc(100% + 40px);
        height: 376px;
      }
    }
  }
}

@include screen('small') {
  .invalidContainer {
    .slideBodyInvalid {
      .personContainer img {
        height: 239px;
      }
    }
  }
}