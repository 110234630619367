@import '../../assets/scss/app-variables';

.helpModalContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  position: relative;

  .closeCross {
    cursor: pointer;
    max-width: 1rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  h4 {
    margin-bottom: $space-between;
  }
}