@import '../../../assets/scss/app-variables';

.modal-container {
  display: table;
  height: 100%;
  position: fixed;
  right: 0;
  transform: translateY(110%);
  top: 0;
  z-index: 1500;

  .modal {
    background-color: white;
    border-radius: 22px;
    box-shadow: 0px -6px 20px 0px $black-20;
    width: 100vw;
    max-width: 40rem;
    max-height: 40rem;
    margin: 0 auto;
  }
}

@keyframes unfoldIn {
  0% {
    transform: translateY(110%);
  }
  100% {
    transform: translateY(5%);
  }
}

@keyframes unfoldOut {
  0% {
    transform: translateY(5%);
  }
  100% {
    transform: translateY(110%);
  }
}

@keyframes unfoldInSmall {
  0% {
    transform: translateY(110%);
  }
  100% {
    transform: translateY(40%);
  }
}

@keyframes unfoldOutSmall {
  0% {
    transform: translateY(40%);
  }
  100% {
    transform: translateY(110%);
  }
}

@include screen('large') {
  .modal-container {
    width: 70%;

    &.modalOpen {
      transform: translateY(110%);
      animation: unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    
    &.modalClosed {
      transform: translateY(5%);
      animation: unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
  }
}
  
@include screen('small') {
  .modal-container {
    &.modalOpen {
      transform: translateY(110%);
      animation: unfoldInSmall 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
    
    &.modalClosed {
      transform: translateY(40%);
      animation: unfoldOutSmall 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }
  }
}
