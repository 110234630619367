@import '../../assets/scss/app-variables';

.intro-container {
  display: flex;
  height: calc(100% - 13px);

  .slideBodyIntro {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);

    .smallRocketShipContainer {
      display: flex;
      justify-content: center;
      margin: $space-between 0;

      img {
        width: 255px;
        height: 265px;
      }
    }

    .welcomeText span {
      color: $white;
    }
  }

  .rocketShipContainer {
    display: flex;
    align-items: flex-end;
    padding: 0;

    img {
      width: 731px;
      height: 700px;
    }
  }
}

@include screen('large') {
  .intro-container {
    justify-content: space-between;

    .slideBodyIntro {
      box-sizing: border-box;
      padding: 7px 0 0 44px;
      width: 375px;
  
      .smallRocketShipContainer {
        display: none;
      }

      .welcomeText,        
      .cta-dialog-container {
        margin-top: $space-between-x3;
      }
    }
  }
}

@include screen('small') {
  .intro-container {
    height: 100%;
    flex-direction: column;

    .slideBodyIntro {
      height: 100%;
      
      .cta-dialog-container {
        margin: $space-between-x2 0 $space-between;
      }
    }

    .rocketShipContainer {
      display: none;
    }
  }
}