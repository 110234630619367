@import '../../assets/scss/app-variables';

.convert-container {
  display: flex;
  height: calc(100% - 13px);

  .slideBodyConvert {
    display: flex;
    flex-direction: column;

    .content {
      h2 {
        margin-bottom: $space-between;
      }
  
      p span {
        color: $secondary-blue;
      }
  
      .phoneSmallContainer {
        margin-top: auto;
  
        img {
          width: 312px;
          height: 378px;
        }
      }
    }
  }

  .phoneContainer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    width: 50%;

    img {
      width: 508px;
      height: 653px;
    }
  }
}

@include screen('large') {
  .convert-container {
    justify-content: space-between;

    .slideBodyConvert {
      margin-top: 7px;
      width: calc(50% - 20px);
  
      .content {
        width: 365px;

        .phoneSmallContainer {
          display: none;
        }
      }
    }
  }
}

@include screen('small') {
  .convert-container {
    height: 100%;
    flex-direction: column;

    .slideBodyConvert {
      height: 100%;

      .content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .phoneContainer {
      display: none;
    }
  }
}